<script lang="ts">
  import SvelteMarkdown from 'svelte-markdown'
  import { MetaTags } from 'svelte-meta-tags'
  import {alert} from 'notie'

  import {
    db,
    collection,
    where,
    doc,
    getDoc,
    getDocs,
    limit,
    query,
  } from '@knekt/firebase';

  import { track } from '../utils/analytics';
  import { renderedImageUrl } from '../utils/cloudinary';
  import { appellation, context, genderPerson } from '../utils/profile';
  import { ellipsed } from '../utils/text';
  import { searchParam } from '../utils/utils';

  import Header from '../shared/Header.svelte';
  import Loading from '../shared/Loading.svelte';
  import TellMeMore from '../shared/TellMeMore.svelte';

  export let questionSlug;
  export let responseId;

  let askingForMore = searchParam('tellmemore') || false;
  let canAskForMore = false;

  async function getQuestion() {
    const q = query(
      collection(db, 'questions'),
      where('slug', '==', questionSlug),
      limit(1)
    );
    const list = await getDocs(q);

    if (!list.size) {
      // add 404 catch here
      console.warn('list for', questionSlug, 'is empty')
      return null
    }

    const qDoc = list.docs[0]

    return {
      id: qDoc.id,
      ...qDoc.data()
    }
  }

  const getResponse = async (questionId) => {
    const rDoc = doc(db, `questions/${questionId}/responses/${responseId}`)
    const resp = await getDoc(rDoc)

    return resp.exists ? resp.data() : null
  }

  const questionPromise = getQuestion();
  const response = questionPromise
    .then((q) => getResponse(q.id))

  const loaded = Promise.all([questionPromise, response])

  loaded.then(([question, response]) => {
    if (!response) {
      console.log('Unable to find response matching', responseId, 'under', question)
      return
    }

    canAskForMore = !!response.permission_tell_me_more
    // Just ensure it's been rendered first :)
    setTimeout(() => {
      createShareToFacebook()
      createTwitterBtn(question, response)
      // @ts-ignore
      window.prerenderReady = true
    }, 25)
  })

  let sharingBlocked = false

  const createShareToFacebook = () => {
    // @ts-ignore
    if (typeof(FB) === 'undefined') {
      console.log('Facebook not loaded, cannot create share button')
      sharingBlocked = true
      return
    }
    // @ts-ignore
    FB.XFBML.parse()
  }

  const createTwitterBtn = (question, response) => {
    // @ts-ignore
    if (typeof(twttr) === 'undefined') {
      console.log('Twitter not loaded, cannot create share button')
      sharingBlocked = true
      return
    }
    // @ts-ignore
    twttr.widgets.createShareButton(
      `https://app.knekt.io/question/${questionSlug}/response/${responseId}`,
      document.getElementById('twitterBtn'),
      {
        dnt: true,
        size: 'large',
        hashtags: 'SeekTheStrange',
        via: 'knektio',
        text: `I answered "${ellipsed(response.answer, 200)}"`
      }
    )
  }

  const askForMore = () => {
    if (!canAskForMore) return
    track('opened-ask-for-more', {question: questionSlug})
    askingForMore = true
  }

  const trackTmm = (sent = false) => {
    askingForMore = false
    if (sent) {
      track('sent-tmm-message', {question: questionSlug});
      alert({
        type: 'success',
        text: `Your message has been sent! You'll receive an email if they reply`
      })
    }
  }

</script>

<style lang="scss">
  @import url('https://unpkg.com/notie/dist/notie.min.css');
  @import '../styles/variables';

  .question {
    font-family: 'Zilla Slab';
    width: 90%;
    margin: 40px auto;
    font-size: 4rem;
    line-height: 1.2;
    text-align: center;

    a {
      color: inherit;
      text-decoration: none;
    }

    @media screen and (max-width: $breakpoint-medium-max) {
      font-size: 3rem;
    }

    @media screen and (max-width: $breakpoint-small) {
      font-size: 2.4rem;
    }
  }

  .response {
    background: url('https://assets.knekt.io/bg/paper_3.png');
    border-radius: 5px;
    padding: $gutter * 3;
    margin: 0 auto ($gutter * 2);

    > :global(p) {
      font-family: 'Koho';
      font-size: 2rem;

      &:not(:last-child) {
        margin-bottom: $gutter * 1.5;
      }

      @media screen and (max-width: $breakpoint-medium-max) {
        font-size: 1.8rem;
      }

      @media screen and (max-width: $breakpoint-small) {
        font-size: 1.4rem;
      }
    }

    @media screen and (max-width: $breakpoint-medium-max) {
      padding: $gutter * 2;
    }

    @media screen and (max-width: $breakpoint-small) {
      padding: $gutter;
    }
  }

  h3 {
    font-size: 2.4rem;
    margin-bottom: $gutter * 2;

    @media screen and (max-width: $breakpoint-medium-max) {
      font-size: 2rem;
    }

    @media screen and (max-width: $breakpoint-small) {
      font-size: 1.8rem;
    }
  }

  h4 {
    font-size: 1.6rem;

    @media screen and (max-width: $breakpoint-medium-max) {
      font-size: 1.4rem;
    }
  }

  h3, h4 {
    font-family: 'Zilla Slab';
    text-align: center;
  }

  .response-actions {
    margin-bottom: $gutter * 3;

    .column {
      text-align: center;

      h4 {
        margin-bottom: $gutter;
      }
    }
  }

  .share .share-row {
    display: flex;
    flex-direction: row;
    width: 600px;
    max-width: 100%;
    margin: 40px auto;
    justify-content: space-evenly;

    &.link {
      align-items: center;
      flex-direction: column;

      h4 {
        margin-bottom: 20px;
      }
    }
  }
</style>

<div class="page">
  {#await loaded}
  <div class="loader">
    <Loading />
  </div>
  {:then [question, response]}
    <Header />
    <MetaTags
      title={question.name}
      titleTemplate="%s | Knekt"
      openGraph={{
        title: `A Response to "${question.name}" | Knekt`,
        description: question.question,
        site_name: 'Knekt',
        url: `https://app.knekt.io/question/${question.slug}`,
        type: 'website',
        images: [
          {
            alt: question.question,
            url: renderedImageUrl(question.question, question.shareBackground),
          }
        ]
      }}
      twitter={{
        handle: '@knektio',
        site: '@knektio',
        cardType: 'summary_large_image',
        title: `A Response to "${ellipsed(question.name, 47)}" | Knekt`,
      }}

      facebook={{
        appId: '2001615883226792'
      }}
      />

      {#if askingForMore}
        <TellMeMore
          name={appellation(response, true)}
          answer={response.answer}
          questionId={question.id}
          responseId={responseId}
          on:closed={(ev) => trackTmm(ev.detail.sent) } />
      {/if}
      <div class="stage">
        <h1 class="question">
          <a
            href={`/question/${question.slug}`}
            title="Submit your own answer to this question!">
            {question.question}
          </a>
        </h1>
        <div class="response">
          {#if response.profile}
            <div class="appellation with-profile">
              <h3>
                {appellation(response)},
                {genderPerson(response)} who is
                {context(response)},
                wrote:
              </h3>
            </div>
          {:else}
            <div class="appellation">
              <h3>{appellation(response)} wrote:</h3>
            </div>
          {/if}
          <SvelteMarkdown source={response.answer} />
        </div>
        <div class="response-actions">
          <h3>
            Found this answer compelling?
          </h3>
          <div class="columns">
            <div class="column respond-self">
              <h4>Share Your Own Perspective</h4>
              <a
                class="cta"
                class:secondary={canAskForMore}
                href={`/question/${question.slug}`}
                title="Submit your own answer to this question!">
                Respond Here
                <!-- Add white logo or K square here -->
              </a>
            </div>
            {#if canAskForMore}
            <div class="column tell-me-more">
              <h4>Ask {appellation(response, true)} For More Information</h4>
              <button
                class="cta primary"
                on:click={() => askForMore()}
                title="Start a conversation with {appellation(response, true)} about their response">
                Start a Conversation!
              </button>
            </div>
            {/if}
          </div>
        </div>
        <div class="share">
          <h3>Share and Encourage Others to Offer Their Perspective!</h3>
          <div class="share-row">
            {#if sharingBlocked}
              <p class="sharing-blocked">
                Social sharing not enabled since Facebook and Twitter cookies have
                been blocked. Please enable them to share more easily to your social
                accounts, or use the link below to share directly.
              </p>
            {/if}
            <div class="facebook">
              <div
                class="fb-share-button"
                data-href={`https://app.knekt.io/question/${questionSlug}/response/${responseId}`}
                data-layout="button"
                data-size="large">
              </div>
            </div>
            <div class="twitter">
              <span id="twitterBtn"></span>
            </div>
          </div>
          <div class="share-row link">
            <h4>Or Simply Copy and Share This Link:</h4>
            <a
              href={`/question/${question.slug}`}
              title="Share this question!">
              https://app.knekt.io/question/{question.slug}
            </a>
          </div>
        </div>
      </div>
    {/await}
</div>
