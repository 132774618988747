<script lang="ts">
  import SvelteMarkdown from 'svelte-markdown'
  import { MetaTags } from 'svelte-meta-tags'

  import {
    db,
    collection,
    where,
    getDocs,
    limit,
    query,
  } from '@knekt/firebase';

  import { renderedImageUrl } from '../utils/cloudinary';
  import { appellation, context, genderPerson } from '../utils/profile';
  import { ellipsed } from '../utils/text';

  import Header from '../shared/Header.svelte';
  import Loading from '../shared/Loading.svelte';

  export let questionSlug;

  async function getQuestion() {
    const q = query(
      collection(db, 'questions'),
      where('slug', '==', questionSlug),
      limit(1)
    );
    const list = await getDocs(q);

    if (!list.size) {
      // add 404 catch here
      console.warn('list for', questionSlug, 'is empty')
      return null
    }

    const qDoc = list.docs[0]

    return {
      id: qDoc.id,
      ...qDoc.data()
    }
  }

  const getResponses = async (questionId) => {
    const coll = collection(db, `questions/${questionId}/responses`)
    const resp = await getDocs(coll)

    return resp.size ? resp.docs.map((d) => {
      return {
        id: d.id,
        ...d.data()
      }
    }) : []
  }

  const questionPromise = getQuestion();
  const responses = questionPromise
    .then((q) => getResponses(q.id))

  const loaded = Promise.all([questionPromise, responses])

  loaded.then(([question, rs]) => {
    if (!rs.length) {
      console.warn('No responses yet for question', question.slug);
      return
    }

    // Just ensure it's been rendered first :)
    setTimeout(() => {
      // @ts-ignore
      window.prerenderReady = true
    }, 25)
  })
</script>

<style lang="scss">
  @use 'sass:math';
  @import '../styles/variables';

  .question {
    font-family: 'Zilla Slab';
    width: 90%;
    margin: 40px auto;
    font-size: 4rem;
    line-height: 1.2;
    text-align: center;

    a {
      color: inherit;
      text-decoration: none;
    }

    @media screen and (max-width: $breakpoint-medium-max) {
      font-size: 3rem;
    }

    @media screen and (max-width: $breakpoint-small) {
      font-size: 2.4rem;
    }
  }

  .response {
    background: url('https://assets.knekt.io/bg/paper_3.png');
    border-radius: 5px;
    padding: $gutter;
    margin: 0 auto ($gutter * 2);

    .text {
      margin-bottom: math.div($gutter, 2);

      > :global(p) {
      font-family: 'Koho';
      font-size: 1.6rem;

      &:not(:last-child) {
        margin-bottom: $gutter;
      }

      @media screen and (max-width: $breakpoint-medium-max) {
        font-size: 1.8rem;
      }

      @media screen and (max-width: $breakpoint-small) {
        font-size: 1.4rem;
      }
    }
    }
  }

  h4 {
    font-size: 1.2rem;

    @media screen and (max-width: $breakpoint-medium-max) {
      font-size: 1.4rem;
    }
  }

  .response-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cta {
      font-size: 1.2rem;
    }
  }

  .share .share-row {
    display: flex;
    flex-direction: row;
    width: 600px;
    max-width: 100%;
    margin: 40px auto;
    justify-content: space-evenly;

    &.link {
      align-items: center;
      flex-direction: column;

      h4 {
        margin-bottom: 20px;
      }
    }
  }
</style>

<div class="page">
  {#await loaded}
  <div class="loader">
    <Loading />
  </div>
  {:then [question, responses]}
    <Header />
    <MetaTags
      title={question.name}
      titleTemplate="%s | Knekt"
      openGraph={{
        title: `Responses to "${question.name}" | Knekt`,
        description: question.question,
        site_name: 'Knekt',
        url: `https://app.knekt.io/question/${question.slug}`,
        type: 'website',
        images: [
          {
            alt: question.question,
            url: renderedImageUrl(question.question),
          }
        ]
      }}
      twitter={{
        handle: '@knektio',
        site: '@knektio',
        cardType: 'summary_large_image',
        title: `Responses to "${ellipsed(question.name, 47)}" | Knekt`,
      }}

      facebook={{
        appId: '2001615883226792'
      }}
      />

      <div class="stage">
        <h1 class="question">
          Responses to:
          <a
            href={`/question/${question.slug}`}
            title="Submit your own answer to this question!">
            {question.question}
          </a>
        </h1>

        {#each responses as response}
        <div class="response">
          <div class="text">
            <SvelteMarkdown source={ellipsed(response.answer, 150)} />
          </div>
          <div class="response-footer">
            {#if response.profile}
              <div class="appellation with-profile">
                <h4>
                  From {appellation(response)},
                  {genderPerson(response)} who is
                  {context(response)}
                </h4>
              </div>
            {:else}
              <div class="appellation">
                <h4>From {appellation(response)}</h4>
              </div>
            {/if}
            <a
              class="cta secondary"
              href={`/question/${question.slug}/response/${response.id}`}
              title="Read {appellation(response, true)}'s whole response, share, and interact with it">
              Read More
            </a>
          </div>
        </div>
        {/each}
      </div>
    {/await}
</div>
