// Handle IE's lack of replaceAll
import "core-js/actual/string/replace-all";
var BG_URL_TPL = "https://res.cloudinary.com/knekt/image/upload/c_scale,w_800/c_fit,co_rgb:FFFFFF,l_text:open%20sans_48_normal_left:{$TEXT},w_600,h_400/fl_layer_apply,g_center/backgrounds/{$BG_IMAGE}";
// TODO - Handle slashes
export var renderedImageUrl = function (text, bgImg) {
    if (bgImg === void 0) { bgImg = "wave_bwix6k.png"; }
    return (BG_URL_TPL.replace("{$TEXT}", encodeURIComponent(text))
        .replace("{$BG_IMAGE}", bgImg)
        // @ts-ignore
        .replaceAll("%2C", "%252C")); // double escape comma for cloudinary
};
