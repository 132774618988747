<script>
  export let formSlug

  const queryString = window.location.search.slice(1); // drop `?`
</script>

<style>
  .form { margin: 0; height: 100%; overflow: hidden; }
  iframe { position: absolute; top: 0; right: 0; bottom: 0; left: 0; border: 0; }
</style>


<div class="form">
  <iframe src="https://forms.knekt.io/{formSlug}?transparentBackground=1&${queryString}" width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0" title="A Delight to Share"></iframe>
</div>