<script lang="ts">
  import Cookies from 'js-cookie';
  import Form from "./form/Form.svelte";
  import Profile from './profile/Profile.svelte'
  import Question from "./question/Question.svelte";
  import Questions from './question/Questions.svelte';
  import Response from "./question/Response.svelte";
  import Responses from './question/Responses.svelte';
  import Share from './question/Share.svelte';
  import Welcome from './shared/Welcome.svelte';
  import { track } from './utils/analytics';


  const isPrerender = navigator.userAgent.toLowerCase().indexOf('prerender') !== -1;

  const pathParts = window
    .location
    .pathname
    .split('/')
    .filter((p) => !!p) // remove any empty ones

    let question;
    let responseId;
    let action;
    let submissionView = false

    if (pathParts.length > 2 && pathParts[0] === 'question') {
      [question, action, responseId] = pathParts.slice(1)
      submissionView = pathParts[2] === 'share'
    } else {
      question = pathParts[pathParts.length - 1];
    }

  const BASE_FORMS = ['suggestion', 'thanks'];

  let dismissedWelcome = !!Cookies.get('knekt-dismissed-welcome');
  const closeWelcome = (dismissed?: boolean) => {
    Cookies.set('knekt-dismissed-welcome', true, !dismissed ? {expires: 1} : null)
    track('dismissed-welcome', {dismissed})
    dismissedWelcome = true
  }
</script>

<style lang="scss" global>
  @import './styles/styles';
</style>

{#if !dismissedWelcome && !isPrerender}
  <Welcome on:closed={({detail}) => closeWelcome(detail.dismissed)} />
{/if}

{#if BASE_FORMS.includes(question)}
  <Form formSlug={question} />
{:else if question === 'profile'}
  <Profile />
{:else if question === 'questions'}
  <Questions />
{:else if responseId}
  {#if submissionView}
    <Share
      questionSlug={question}
      submissionId={responseId} />
  {:else}
    <Response
      questionSlug={question}
      responseId={responseId} />
  {/if}
{:else if action === 'responses'}
  <Responses questionSlug={question} />
{:else}
  <Question questionSlug={question} />
{/if}
