<script lang="ts">
  import {createEventDispatcher} from 'svelte'

  const dispatch = createEventDispatcher();

  let step = 0;
  const lastStep = 2;

  const close = (dismissed = false) => {
    dispatch('closed', {dismissed})
  }

</script>

<div class="modal-stage">
  <div class="modal welcome">
    <button
      class="close"
      title="Close welcome modal"
      on:click={() => close()}>
      &times;
    </button>
    <h1 class="modal-title">
      Thanks For Sharing
    </h1>

    <div class="modal-content">
      <h2>What's Next</h2>
      <div class="how-it-works block">
        <h3>We'll collect everyone's responses</h3>
        <p>
          Over the next week, people can share their own answers and perspectives.
          Know someone who you think the world would benefit from hearing from? Invite
          them to share their thoughts!
        </p>
      </div>
      <div class="how-it-works block">
        <h3>
          We'll share those via email and social media
        </h3>
        <p>
          We'll share those responses (anonymously, of course) and invite people to
          ask for more information. If you've checked the box to allow people to reach out,
          they'll be able to send you a message (again, anonymously, for both you and them),
          and you can start a conversation (if you're willing. Don't worry, you'll never be
          spammed).
        </p>
      </div>
      <div class="how-it-works block">
        <h3>Rinse. Wash. Repeat.</h3>
        <p>
          Each week, you'll have three opportunities to share your perspective,
          and numerous chances to learn from others! We hope you'll not only
          gain new insight into the people around you, but also grow in your
          understanding of yourself, as we believe answering these questions can
          be incredibly helpful in clarifying our own thoughts and beliefs.
        </p>
      </div>
      <div class="know-more block">
        <h3>Want to know more?</h3>
        <p>
          You can read all about what we're doing and how it works at our site:
          <a href="https://knekt.io" target="_blank">knekt.io</a>
        </p>
      </div>
    </div>
    <div class="modal-footer">
      <a class="dont-show" href="#" on:click={() => close(true)}>
        Don't Show This Again
      </a>
      <button class="advance" on:click={() => close()}>
        Sounds Good!
      </button>
    </div>
  </div>
</div>