<script lang="ts">
  import SvelteMarkdown from 'svelte-markdown'
  import { MetaTags } from 'svelte-meta-tags'

  import {
    db,
    collection,
    where,
    getDocs,
    limit,
    orderBy,
    query,
  } from '@knekt/firebase';

  import { renderedImageUrl } from '../utils/cloudinary';

  import Header from '../shared/Header.svelte';
  import Loading from '../shared/Loading.svelte';

  async function getQuestions() {
    const q = query(
      collection(db, 'questions'),
      where('live', '==', true),
      where('publishedAt', '<=', new Date()),
      orderBy('publishedAt', 'desc')
    );
    const list = await getDocs(q);

    if (!list.size) {
      // add 404 catch here
      console.warn('Unable to load questions');
      return null
    }

    return list.docs.map((qDoc) => {
      return {
        id: qDoc.id,
        ...qDoc.data()
      }
    })
  }

  const questionPromise = getQuestions();

  questionPromise.then(() => {
    // Just ensure it's been rendered first :)
    setTimeout(() => {
      // @ts-ignore
      window.prerenderReady = true
    }, 25)
  })
</script>

<style lang="scss">
  @use 'sass:math';
  @import '../styles/variables';

  .question {
    background: url('https://assets.knekt.io/bg/paper_3.png');
    border-radius: 5px;
    padding: $gutter;
    margin: 0 auto ($gutter * 2);

    .text {
      margin-bottom: math.div($gutter, 2);

      font-family: 'Koho';
      font-size: 1.6rem;

      @media screen and (max-width: $breakpoint-medium-max) {
        font-size: 1.8rem;
      }

      @media screen and (max-width: $breakpoint-small) {
        font-size: 1.4rem;
      }
    }
  }

  .question-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cta {
      font-size: 1.2rem;
    }
  }
</style>

<div class="page">
  {#await questionPromise}
  <div class="loader">
    <Loading />
  </div>
  {:then questions}
    <Header />
    <MetaTags
      title="All Questions"
      titleTemplate="%s | Knekt"
      openGraph={{
        title: `All Questions | Knekt`,
        description: "View all the questions we've discussed so far",
        site_name: 'Knekt',
        url: `https://app.knekt.io/questions`,
        type: 'website',
        images: [
          {
            alt: "All of our questions",
            url: renderedImageUrl("Knekt Questions"),
          }
        ]
      }}
      twitter={{
        handle: '@knektio',
        site: '@knektio',
        cardType: 'summary_large_image',
        title: `All Questions | Knekt`,
      }}

      facebook={{
        appId: '2001615883226792'
      }}
      />

      <div class="stage">
        <h1 class="question">
          Review and respond to all published questions
        </h1>

        {#each questions as question}
        <div class="question">
          <div class="text">
            #{question.index}: {question.question}
          </div>
          <div class="question-footer">
            <a
              class="cta secondary"
              href={`/question/${question.slug}/responses`}
              title="View others' responses">
              Read the responses
            </a>
            <a
              class="cta"
              href={`/question/${question.slug}`}
              title="Share your response">
              Share your perspective
            </a>
          </div>
        </div>
        {/each}
      </div>
    {/await}
</div>
