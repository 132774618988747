export var identify = function (userId, userInfo) {
    // @ts-ignore
    if (!window.posthog)
        return;
    // @ts-ignore
    posthog.identify(userId, userInfo);
};
export var track = function (event, eventData) {
    // @ts-ignore
    if (!window.posthog)
        return;
    // @ts-ignore
    posthog.capture(event, eventData);
};
