export var wait = function (ms) {
    return new Promise(function (res) {
        setTimeout(res, ms);
    });
};
var formatValue = function (val) {
    switch (val) {
        case "true":
        case "TRUE":
        case "1":
            return true;
        case "false":
        case "FALSE":
        case "0":
            return false;
        default:
            return val;
    }
};
export var searchParams = function () {
    var search = window.location.search;
    if (!search.length)
        return {};
    var pairs = search.slice(1).split("&");
    var out = {};
    for (var _i = 0, pairs_1 = pairs; _i < pairs_1.length; _i++) {
        var p = pairs_1[_i];
        var _a = p.split("="), key = _a[0], value = _a[1];
        out[key] = typeof value !== "undefined" ? formatValue(value) : true;
    }
    return out;
};
var parsedParams = null;
export var searchParam = function (param) {
    if (!parsedParams) {
        parsedParams = searchParams();
    }
    return parsedParams[param] || null;
};
