var anonFirstNames = ["na", "n/a", "anon", "anonymous", "null", "none"];
export var appellation = function (response, firstNameOnly) {
    if (firstNameOnly === void 0) { firstNameOnly = false; }
    var profile = response.profile;
    var firstName = response.firstName.trim();
    var anon = !firstName || anonFirstNames.includes(firstName.toLowerCase());
    var name = anon ? "Anonymous" : firstName;
    if (firstNameOnly)
        return name;
    var pronouns = (profile === null || profile === void 0 ? void 0 : profile.pronouns) ? " (".concat(profile.pronouns, ")") : "";
    return name + pronouns;
};
var vowels = ["a", "e", "i", "o", "u"];
export var contextIntro = function (prop, value) {
    switch (prop) {
        default:
            var firstLetter = value[0].toLowerCase();
            return vowels.includes(firstLetter) ? "an" : "a";
    }
};
export var genderPerson = function (response) {
    var profile = response.profile;
    if (!profile || !profile.gender)
        return "a person";
    var intro = contextIntro("gender", profile.gender);
    return "".concat(intro, " ").concat(profile.gender, " person");
};
export var profileFormatter = function (prop, value) {
    switch (prop) {
        case "has_children":
            return value === "Yes"
                ? "who has children"
                : "who does not have children";
        case "disability":
            var out = value.map(function (v) {
                if (v.toLowerCase().indexOf("disability") >= 0) {
                    return v.replace("I have", "who has");
                }
                else {
                    return v.replace("I am", "who is");
                }
            });
            return out.join(", ");
        case "education":
            switch (value) {
                case "None":
                    return "with no schooling";
                default:
                    return "educated through ".concat(value);
            }
        case "military_service":
            if (value === "No") {
                return "who has not served in the military";
            }
            else {
                return value;
            }
        case "politics":
            return "politically ".concat(value);
        case "relationship":
            if (value.indexOf("Relationship") >= 0) {
                return "in a ".concat(value);
            }
            else {
                return value;
            }
        default:
            return value;
    }
};
export var context = function (response) {
    var keyProperties = response.keyProperties, profile = response.profile;
    if (!(keyProperties === null || keyProperties === void 0 ? void 0 : keyProperties.length))
        return "strange";
    var vals = keyProperties
        .map(function (k) { return (profile[k] ? profileFormatter(k, profile[k]) : false); })
        .filter(function (v) { return !!v; });
    return "".concat(vals.join(", "));
};
