<script>
  import {db, doc, getDoc, getCurrentUser} from '@knekt/firebase';

  const getUserProfile = async (userId) => {
    const d = await getDoc(
      doc(db, 'profiles', userId)
    )

    return d.exists ? d.data() : null
  }

  const currentUser = getCurrentUser()

  const currentProfile = currentUser
    .then((u) => {
      return getUserProfile(u.uid)
    })

  const keysToExclude = [
    'updatedAt',
    'firstName',
    'email'
  ];
  const profileToQuery = (profile) => {
    if (!profile) return '';//'&allowjump=true';
    const out = [
    ];
    for (const key in profile) {
      if (keysToExclude.includes(key) || !profile[key]) continue;
      out.push(
        `prefill_${key}=${encodeURIComponent(profile[key])}`
      )
    }

    if (out.length) {
      out.push('allowjump=true') // if they've filled this out before, allow skipping
    }

    return '&' + out.join('&')
  }

  const loaded = Promise.all([currentProfile, currentUser])

  // pass this along as well
  const queryString = window.location.search.slice(1);
</script>

<style>
  .question { margin: 0; height: 100%; overflow: hidden; }
  iframe { position: absolute; top: 0; right: 0; bottom: 0; left: 0; border: 0; }
</style>

{#await loaded}
<p>Loading...</p>
{:then [profile, user]}
<div class="question">
  <iframe src="https://forms.knekt.io/profile?transparentBackground=1&user_id={user.uid}{profileToQuery(profile)}&{queryString}" width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0" title="A Delight to Share"></iframe>
</div>
{/await}