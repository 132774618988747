<script lang="ts">
  import { MetaTags } from 'svelte-meta-tags'
  import {
    collection,
    db,
    doc,
    getCurrentUser,
    getDoc,
    getDocs,
    limit,
    orderBy,
    query,
    where,
  } from '@knekt/firebase';
  import Loading from '../shared/Loading.svelte';

  import {renderedImageUrl} from '../utils/cloudinary'
  import {ellipsed} from '../utils/text'
  import { identify } from '../utils/analytics';

  export let questionSlug;

  async function getQuestion() {
    let q;
    if (questionSlug === 'latest') {
      console.log('[debug] getting latest question, based on slug of', questionSlug)
      q = query(
        collection(db, 'questions'),
        where('live', '==', true),
        where('publishedAt', '<=', new Date()),
        orderBy('publishedAt', 'desc'),
        limit(1)
      )
    } else {
      console.log('[debug] getting question with slug of', questionSlug)
      q = query(
        collection(db, 'questions'),
        where('slug', '==', questionSlug),
        limit(1)
      );
    }

    const list = await getDocs(q)

    if (!list.size) {
      // add 404 catch here
      console.warn('list for', questionSlug, 'is empty')
      return null
    }

    return list.docs[0].data()
  }

  const questionPromise = getQuestion();
  const getUserProfile = async (userId) => {
    const d = await getDoc(
      doc(db, 'profiles', userId)
    )

    return d.exists ? d.data() : null
  }

  const currentUser = getCurrentUser()

  const currentProfile = currentUser
    .then((u) => {
      return getUserProfile(u.uid)
        .then((p) => {
          if (p) {
            identify(u.uid, {
              firstName: p.firstName,
              email: p.email
            })
          }
          return p
        })
    })
  const loaded = Promise.all([questionPromise, currentUser, currentProfile])

  const keysToInclude = ['firstName', 'email'];
  const profileToQuery = (profile) => {
    if (!profile) return '';
    const out = [];
    for (const key of keysToInclude) {
      if (!profile[key]?.trim()) continue;
      out.push(
        `prefill_${key}=${encodeURIComponent(profile[key])}`
      )
    }

    return '&' + out.join('&')
  }

  const questionUrl = (question) => {
    if (!question.embedded) {
      return `https://tally.so/r/${question.tallyId}`
    } else {
      return `https://forms.knekt.io/${question.slug}`
    }

  }

  loaded.then(() => {
    setTimeout(() => {
      // @ts-ignore
      window.prerenderReady = true
    }, 25)
  })
</script>

<style>
  .question { margin: 0; height: 100%; overflow: hidden; }
  iframe { position: absolute; top: 0; right: 0; bottom: 0; left: 0; border: 0; }
  .loader {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
</style>

{#await loaded}
<div class="loader">
  <Loading/>
</div>
{:then [question, user, profile]}
<MetaTags
  title={question.name}
  titleTemplate="%s | Knekt"
  openGraph={{
    title: `${question.name} | Knekt`,
    description: question.question,
    site_name: 'Knekt',
    url: `https://app.knekt.io/question/${question.slug}`,
    type: 'website',
    images: [
      {
        alt: question.question,
        url: renderedImageUrl(question.question, question.shareBackground),
      }
    ]
  }}
  twitter={{
    handle: '@knektio',
    site: '@knektio',
    cardType: 'summary_large_image',
    title: `${ellipsed(question.name, 62)} | Knekt`
  }}

  facebook={{
    appId: '2001615883226792'
  }}
  />
<div class="question">
  <iframe src="{questionUrl(question)}?transparentBackground=1&user_id={user.uid}&slug={question.slug}{profileToQuery(profile)}" width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0" title="A Delight to Share"></iframe>
</div>
{/await}