<style lang="scss">
  @import '../styles/variables';

  header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: fixed;
    height: 20vh;
    right: $gutter * 2;

    a {
      display: inline-block;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    @media screen and (max-width: $breakpoint-medium-max) {
      height: 10vh;
    }

    @media screen and (max-width: $breakpoint-small) {
      height: 5vh;
      right: $gutter;
    }
  }
</style>

<header>
  <a href="https://app.knekt.io/question/latest">
    <img
      alt="Knekt logo"
      src="https://assets.knekt.io/images/logo.png" />
  </a>
</header>