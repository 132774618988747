<script lang="ts">
  import Cookies from 'js-cookie';
  import { MetaTags } from 'svelte-meta-tags'

  import {
    db,
    collection,
    where,
    getDocs,
    limit,
    query,
  } from '@knekt/firebase';
  import Header from '../shared/Header.svelte';
  import { renderedImageUrl } from '../utils/cloudinary';
  import { wait } from '../utils/utils';
  import { ellipsed } from '../utils/text';
  import Loading from '../shared/Loading.svelte';
import { track } from '../utils/analytics';
import WhatsNext from '../shared/WhatsNext.svelte';

  export let questionSlug;
  export let submissionId;

  async function getQuestion() {
    const q = query(
      collection(db, 'questions'),
      where('slug', '==', questionSlug),
      limit(1)
    );
    const list = await getDocs(q);

    if (!list.size) {
      // add 404 catch here
      console.warn('list for', questionSlug, 'is empty')
      return null
    }

    const qDoc = list.docs[0]

    return {
      id: qDoc.id,
      ...qDoc.data()
    }
  }

  const getResponse = async (questionId, submissionId, count = 0) => {
    if (count) {
      // Retrying, pause for a moment
      await wait(250);
    }
    const q = query(
      collection(db, `questions/${questionId}/responses`),
      where('submissionId', '==', submissionId),
      limit(1)
    )

    const list = await getDocs(q)

    if (!list.size) {
      if (count >= 100) {
        console.warn('Response corresponding to submission', submissionId, 'not loaded after 25 seconds')
        return null
      }
      return getResponse(questionId, submissionId, count++)
    }

    console.log('Response for', submissionId, 'loaded after', count * 250, 'ms')

    const rDoc = list.docs[0]

    return {
      id: rDoc.id,
      ...rDoc.data()
    }
  }

  const questionPromise = getQuestion();
  const responsePromise = questionPromise
    .then((q) => getResponse(q.id, submissionId))

  const loaded = Promise.all([questionPromise, responsePromise])

  loaded.then(([question, response]) => {
    if (!response) {
      console.log('Unable to find response matching', submissionId, 'under', question)
      return
    }
    // Just ensure it's been rendered first :)
    setTimeout(() => {
      createShareToFacebook()
      createTwitterBtn(response)
    }, 25)
  })

  const createShareToFacebook = () => {
    // @ts-ignore
    if (!window.FB) return
    // @ts-ignore
    FB.XFBML.parse()
  }

  const createTwitterBtn = (response) => {
    // @ts-ignore
    if (!window.twttr) return
    // @ts-ignore
    twttr.widgets.createShareButton(
      `https://app.knekt.io/question/${questionSlug}/response/${response.id}`,
      document.getElementById('twitterBtn'),
      {
        dnt: true,
        size: 'large',
        hashtags: 'SeekTheStrange',
        via: 'knektio',
        text: `I answered "${ellipsed(response.answer, 200)}"`
      }
    )
  }

  let dismissedWhatsNext = !!Cookies.get('knekt-dismissed-whatsnext');
  const closeWhatsNext = (dismissed?: boolean) => {
    Cookies.set('knekt-dismissed-whatsnext', true, !dismissed ? {expires: 1} : null)
    track('dismissed-whatsnext', {dismissed})
    dismissedWhatsNext = true
  }

</script>

<style lang="scss">
  @import '../styles/variables';

  .question {
    font-family: 'Zilla Slab';
    width: 50%;
    max-width: 800px;
    margin: 40px auto;
    font-size: 4rem;
    line-height: 1.2;
    text-align: center;

    @media screen and (max-width: $breakpoint-medium-max) {
      width: 80%;
      font-size: 3rem;
    }

    @media screen and (max-width: $breakpoint-small) {
      width: 90%;
      font-size: 2.4rem;
    }
  }

  h2 {
    font-size: 2.8rem;
    margin-bottom: $gutter * 2;

    @media screen and (max-width: $breakpoint-medium-max) {
      font-size: 2.4rem;
    }

    @media screen and (max-width: $breakpoint-small) {
      font-size: 2rem;
    }
  }

  h3 {
    font-size: 2.4rem;
    margin-bottom: $gutter * 2;

    @media screen and (max-width: $breakpoint-medium-max) {
      font-size: 2rem;
    }

    @media screen and (max-width: $breakpoint-small) {
      font-size: 1.8rem;
    }
  }

  h4 {
    font-size: 1.6rem;

    @media screen and (max-width: $breakpoint-medium-max) {
      font-size: 1.4rem;
    }
  }

  h2, h3, h4 {
    font-family: 'Zilla Slab';
    text-align: center;
  }

  .go-to-response {
    margin-bottom: 40px;

    a {
      font-family: 'Barlow';
      background-color: #E64325;
      color: white;
      padding: 10px 20px;
      border-radius: 5px;
      text-decoration: none;
      font-size: 1.4rem;
      text-align: center;

      @media screen and (max-width: $breakpoint-small) {
        font-size: 1.2rem;
      }
    }
  }

  .suggestion {
    .link-wrapper {
      text-align: center;
    }

    a {
      font-family: 'Barlow';
      color: #E64325;
      text-decoration: none;
      font-size: 1.4rem;
      font-weight: bold;
    }
  }

  .share {
    .share-row {
      display: flex;
      flex-direction: row;
      width: 600px;
      max-width: 100%;
      margin: 40px auto;
      justify-content: space-evenly;

      &.link {
        align-items: center;
        flex-direction: column;

        h4 {
          margin-bottom: 20px;
        }
      }
    }
  }
</style>

<div class="page">
  {#if !dismissedWhatsNext}
  <WhatsNext on:closed={({detail}) => closeWhatsNext(detail.dismissed)} />
  {/if}
  {#await questionPromise}
  <div class="loader">
    <Loading />
  </div>
  {:then question}
    <Header />
    <MetaTags
      title={question.name}
      titleTemplate="%s | Knekt"
      openGraph={{
        title: `A Response to "${question.name}" | Knekt`,
        description: question.question,
        site_name: 'Knekt',
        url: `https://app.knekt.io/question/${question.slug}`,
        type: 'website',
        images: [
          {
            alt: question.question,
            url: renderedImageUrl(question.question),
          }
        ]
      }}
      twitter={{
        handle: '@knektio',
        site: '@knektio',
        cardType: 'summary_large_image',
        title: `A Response to "${ellipsed(question.name, 47)}" | Knekt`,
      }}

      facebook={{
        appId: '2001615883226792'
      }}
      />

      <h1 class="question">
        Thanks so much for sharing with us!
      </h1>
      <h2 class="view-others">
        Have more to say?
        <br />
        Check out all of our questions
        <a href="/questions">here</a>!
      </h2>
      <div class="share">
        <h3>
          Found this question compelling?
          <br />
          Share and Encourage Others to Offer Their Perspective!
        </h3>
        {#await responsePromise}
        <div class="share-row loading"></div>
        {:then response}
        <div class="share-row">
          <div class="facebook">
            <div
              class="fb-share-button"
              data-href={`https://app.knekt.io/question/${questionSlug}/response/${response.id}`}
              data-layout="button"
              data-size="large">
            </div>
          </div>
          <div class="twitter">
            <span id="twitterBtn"></span>
          </div>
        </div>
        {/await}
        <div class="share-row link">
          <h4>Or Simply Copy and Share This Link:</h4>
          <a
            href={`https://app.knekt.io/question/${question.slug}`}
            title="Share this question!">
            https://app.knekt.io/question/{question.slug}
          </a>
        </div>
      </div>
      {#await responsePromise then response}
      <div class="go-to-response">
        <h4>
          <a
            href={`https://app.knekt.io/question/${question.slug}/response/${response.id}`}
            title="View my response page!">
            Preview <span class="hide-mobile">how others will see</span> your response &rsaquo;
            <!-- Add white logo or K square here -->
          </a>
        </h4>
      </div>
      {/await}
      <div class="suggestion">
        <h3>
          Have a suggestion or concern?
        </h3>
        <div class="link-wrapper">
          <a
            href={`https://app.knekt.io/suggestion`}
            title="Give us feedback">
            Tell us here!
            <!-- Add white logo or K square here -->
          </a>
        </div>
      </div>
    {/await}
</div>
