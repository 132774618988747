<script lang="ts">
  import {
    callable,
    db,
    doc,
    getDoc,
    getCurrentUser,
  } from '@knekt/firebase';

  import {
    createEventDispatcher,
    onMount
  } from 'svelte'
  import { searchParam } from '../utils/utils';

  export let name;
  export let answer;

  export let questionId;
  export let responseId;

  let message = '';
  let email: string = searchParam('email') as string || ''
  let firstName: string = ''

  const dispatch = createEventDispatcher();

  const tellMeMoreFn = callable('startTellMeMore');

  const startTellMeMore = async () => {
    sending = true
    const req = {
      questionId,
      responseId,
      user: {
        email,
        firstName
      },
      message
    }

    console.log('calling start tmm with', req)
    await tellMeMoreFn(req)
      .catch((err) => {
        console.warn('Error calling tmm', err)
      })
    close(true)
  }

  const close = (sent?: boolean) => {
    dispatch('closed', {sent})
  }

  if (!email || !firstName) {
    const getUserProfile = async (userId) => {
    const d = await getDoc(
      doc(db, 'profiles', userId)
    )

    return d.exists ? d.data() : null
  }

    const currentUser = getCurrentUser()

    const currentProfile = currentUser
      .then((u) => {
        return getUserProfile(u.uid)
      })

    currentProfile
      .then((p) => {
        email = email || p?.email
        firstName = p?.firstName
      })
  }

  let firstInput;

  let sending = false

  $: canSend = (!sending && message.trim().length && email?.length && firstName?.length)

  onMount(() => {
    setTimeout(() => {
      firstInput.focus()
    }, 0)
  })
</script>

<style lang="scss">
  @use 'sass:math';
  @import '../styles/variables';
</style>

<div class="modal-stage">
  <div class="modal welcome">
    <button
      class="close"
      title="Close welcome modal"
      on:click={() => close()}>
      &times;
    </button>
    <h1 class="modal-title">
      Ask {name} For More Info
    </h1>

    <div class="modal-content">
      <h2>
        THIS IS A BIG DEAL (truly)
      </h2>
      <p>
        Having conversations with others is the heart of what makes Knekt
        powerful. Sharing and reading perspectives are important first steps,
        but this action, the one you're taking right now, is what enables true
        transformation.
      </p>
      <div class="input-wrapper">
        <textarea
          bind:value={message}
          bind:this={firstInput}
          placeholder="Tell {name} what's on your mind, what intrigued or confused you about their answer, or how your own view difers" />
      </div>

      <div class="input-wrapper">
        <input
          type="text"
          bind:value={firstName}
          required
          placeholder="Your first name" />
        <span class="hint">
          If you'd rather {name} not know your name, simply enter "Anonymous"
        </span>
      </div>

      <div class="input-wrapper">
        <input
          type="email"
          bind:value={email}
          required
          placeholder="Your email address" />
        <span class="hint">
          This will only be used to send {name}'s replies to you. {name} will never see this directly.
        </span>
      </div>

    </div>
    <div class="modal-footer">
      <a class="dont-show" href="#" on:click={() => close()}>
        Not Right Now
      </a>
      <button
        class="advance"
        disabled={!canSend}
        on:click={() => startTellMeMore()}>
        {sending ? 'Sending...' : 'Send Message'}
      </button>
    </div>
  </div>
</div>