<script lang="ts">
  import {createEventDispatcher} from 'svelte'
import { track } from '../utils/analytics';

  const dispatch = createEventDispatcher();

  let step = 0;
  const lastStep = 2;

  const close = (dismissed = false) => {
    dispatch('closed', {dismissed})
  }

  const next = () => {
    step = step + 1
    track('advanced-welcome', {step})
  }

</script>

<div class="modal-stage">
  <div class="modal welcome">
    <button
      class="close"
      title="Close welcome modal"
      on:click={() => close()}>
      &times;
    </button>
    <h1 class="modal-title">
      {#if step === 0}
        Welcome to Knekt!
      {:else if step === 1}
        Our Principles
      {:else if step === 2}
        How It Works
      {/if}
    </h1>

    <div class="modal-content">
      {#if step === 0}
        <h2>Hello Stranger!</h2>
        <div class="img-wrap">
          <img
            alt="Dog waving hello"
            src="https://media1.giphy.com/media/5L57f5fI3f2716NaJ3/giphy.gif?cid=ecf05e47e9sdz237hb6knj1rq7h7jcr1kf12z0i99tg6kpil&rid=giphy.gif&ct=g" />
        </div>
        <p>
          We're SO glad that you're here, and just wanted to say hi and let you know
          what we're about before you dive in.
        </p>
        <h3>
          Not sure what this is about?
          <a href="https://knekt.io" target="_blank">Learn more here!</a>
        </h3>
      {:else if step === 1}
        <h2>Why Stranger?</h2>
        <p>
          First off, we call everyone a "stranger", because we believe that:
        </p>
        <p class="emphasis">
          To be strange is to have something to teach!
        </p>
        <p>
          We believe strangeness is what enables us to learn from, and teach, the
          world around us.
        </p>
      {:else if step === 2}
        <div class="principle block">
          <h3>Surface Answers are Bulls#!t</h3>
          <p>
            You've chosen to be here, don't sell yourself (and others) short by
            half-assing an answer
          </p>
        </div>
        <div class="principle block">
          <h3>Start From Grace</h3>
          <p>
            We're all weird. You, me, everyone. That guarantees we'll
            say something that doesn't quite track with someone else, and vice
            versa. If you're here, we assume you <strong>want to
            understand, and to be understood</strong>.
          </p>
        </div>
      {:else if step === 3}
        <div class="how-it-works block">
          <h3>Tell Us Your Perspective</h3>
          <p>
            When we share a question, tell us your thoughts,
            whether they be a few words or a whole essay 🤓
          </p>
        </div>
        <div class="how-it-works block">
          <h3>Share Where You're Coming From</h3>
          <p>
            Tell us a bit (all question are optional) about who you are,
            your beliefs, and your life experiences.
          </p>
        </div>
        <div class="how-it-works block">
          <h3>Learn About Someone Else's World</h3>
          <p>
            A week later, we'll share everyone's responses (anonymously)
            via email with you, along with some context from their profile.
          </p>
        </div>
        <div class="how-it-works block">
          <h3>Start a Discussion</h3>
          <p>
            Anonymously reach out about someone's response,
            sharing your answer and building a conversation from there.
          </p>
        </div>
      {/if}
    </div>
    <div class="modal-footer">
      <a class="dont-show" href="#" on:click={() => close(true)}>
        Don't Show This Again
      </a>
      {#if step === lastStep}
        <button class="advance" on:click={() => close()}>
          I Agree
        </button>
      {:else if step === 0}
        <button class="advance" on:click={() => next()}>
          Why Stranger?
        </button>
      {:else if step === 1}
        <button class="advance" on:click={() => next()}>
          Our Principles
        </button>
      {:else if step === 2}
        <button class="advance" on:click={() => next()}>
          How It Works
        </button>
      {/if}
    </div>
  </div>
</div>